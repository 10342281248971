.outputs-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #363636 !important;
}

.outputs-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: dimgray;
  pointer-events: none;
}

.outputs-button {
  margin: 0px !important;
  margin-top: 10px !important;
}

.outputs-form-channels .ant-form-item {
  width: 50%;
}

.outputs-form-channel-title {
  width: 10.5rem;
  font-weight: bold;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.outputs-form .ant-col {
  padding: 0 1.5rem 0 0;
}
